import React from "react";

/**
 *
 * @param {{
 *  variant: "dotted-line" | "rect" | "line";
 *  color: string;
 * }} props
 */
export const LegendIcon = (props) => {
  if (props.variant === "dotted-line") {
    return (
      <svg
        style={{
          width: "19px",
          verticalAlign: "middle",
          fill: props.color,
          overflow: "hidden",
        }}
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M304.43 532.76H221.4c-11.47 0-20.76-9.3-20.76-20.76s9.29-20.76 20.76-20.76h83.03c11.47 0 20.76 9.3 20.76 20.76s-9.29 20.76-20.76 20.76zM581.19 532.76H442.81c-11.47 0-20.76-9.3-20.76-20.76s9.29-20.76 20.76-20.76h138.38c11.47 0 20.76 9.3 20.76 20.76s-9.3 20.76-20.76 20.76zM802.59 532.76h-83.03c-11.47 0-20.76-9.3-20.76-20.76s9.29-20.76 20.76-20.76h83.03c11.47 0 20.76 9.3 20.76 20.76s-9.29 20.76-20.76 20.76z" />
      </svg>
    );
  } else if (props.variant === "rect") {
    return (
      <div
        style={{
          width: "10px",
          height: "10px",
          backgroundColor: props.color,
        }}
      />
    );
  } else if (props.variant === "line") {
    return (
      <div
        style={{
          width: "13px",
          height: "4px",
          backgroundColor: props.color,
        }}
      />
    );
  }
  return <span></span>;
};

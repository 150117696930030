import React from "react";

/**
 * @param {{
 *  icon: import("react").ReactNode;
 *  label: import("react").ReactNode;
 * }} props
 */
export function LegendItem(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {props.icon}
      {props.label}
    </div>
  );
}

import './TopAppBar.scss';

import React, { useEffect, useState } from 'react';

import { Settings } from '../theme/Icons.jsx';
import UserAvatar from '../profile/UserAvatar.jsx';
import UserNotifications from '../profile/UserNotifications.jsx';
import { Link, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

let headerClass = {
  'mdc-top-app-bar--fixed': true,
  'mdc-top-app-bar--prominent': true,
  'mdc-top-app-bar--fixed mdc-top-app-bar--prominent': true,
  'mdc-top-app-bar--dense': true,
  'mdc-top-app-bar--prominent mdc-top-app-bar--dense': true,
  'mdc-top-app-bar--fixed mdc-top-app-bar--prominent mdc-top-app-bar--dense': true,
  'mdc-top-app-bar--short mdc-top-app-bar--short-has-action-item': false,
  'mdc-top-app-bar--short mdc-top-app-bar--short-collapsed mdc-top-app-bar--short-has-action-item': false
};

const getActiveApp = () => {
  const url = window?.location?.href || '';
  if (url.includes('/ui/admin')) {
    return 'Administration';
  } else if (url.includes('/ui/cbm')) {
    return 'Condition Based Maintenance';
  } else if (url.includes('/ui/vantage-dashboard')) {
    return 'Vantage Reports';
  } else if (url.includes('/ui/reflow')) {
    return 'Building Details';
  } else if (url.includes('/ui/smart-cleaning')) {
    return 'Smart Cleaning';
  } else if (url.includes('/ui/issue')) {
    return 'Issues';
  } else if (url.includes('/ui/legionella-monitoring')) {
    return 'Legionella Monitoring';
  } else if (url.includes('/ui/maintenance')) {
    return 'Maintenance';
  } else {
    return 'Home';
  }
};

function Title({ children, color = '#1A1A1A' }) {
  return (
    <Typography
      id="topbar-heading"
      variant="h6"
      noWrap
      component="div"
      sx={{ color, letterSpacing: 0 }}
      className={'mdc-top-app-bar__title'}
    >
      {children}
    </Typography>
  );
}

function TopAppBar({ navigateTo, title }) {
  let location = useLocation();
  let userProfilePic = false;

  let path = location.pathname.startsWith('/demo') ? '/demo' : '/';

  useEffect(() => {});

  return (
    <AppBar
      position="fixed"
      elevation={0}
      className="mui-top-app-bar"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#fff'
        // boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.14)",
      }}
    >
      <Toolbar>
        {/* TODO match this to figma app title Calibre */}
        <Stack direction="row" alignItems="center" gap="8px">
          <Link underline="hover" color="inherit" to={path}>
            <Title color="#003F2D">CBRE Smart FM Solutions</Title>
          </Link>
          <Title>|</Title>
          <Title>{getActiveApp()}</Title>
        </Stack>
        <Box sx={{ marginLeft: 'auto' }}>
          {/* <Box className="top-app-bar-right-icons">
            <div className="user-settings-icon">
              <Settings />
            </div>
            <UserNotifications />
          </Box> */}
          <UserAvatar />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default TopAppBar;

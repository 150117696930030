import {
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@mui/material';
import React from 'react';
import { category, widgets } from './widgets.jsx';

export const WidgetsDrawer = ({
  toggleDrawer,
  sideSheetState,
  dashboardTitle,
  handleDashboardTitle,
  preselectedLayout,
  handlePreselectedLayout,
  widgetCategory,
  handleWidgetCategory,
  gridState,
  onTakeItem
}) => {
  return (
    <Drawer
      sx={{
        width: 480,
        '& .MuiDrawer-paper': {
          width: 480,
          height: 'calc(100vh - 56px)',
          top: 56,
          flexShrink: 0
        }
      }}
      variant="persistent"
      anchor="right"
      open={sideSheetState}
      onClose={toggleDrawer('right', false)}
    >
      <div className="drawer-wrapper">
        <IconButton
          color="inherit"
          aria-label="close drawer"
          edge="end"
          onClick={toggleDrawer('right', false)}
          sx={{ position: 'absolute', right: '16px', top: '16px' }}
        >
          <span className="material-icons" style={{ fontSize: '20px' }}>
            close
          </span>
        </IconButton>

        <Typography
          sx={{
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
            color: '#1A1A1A',
            letterSpacing: 0
          }}
        >
          Edit dashboard
        </Typography>

        {/* <TextField
          sx={{ marginTop: '20px' }}
          fullWidth
          key="Dashboard title"
          label="Dashboard title"
          variant="outlined"
          value={dashboardTitle}
          onChange={handleDashboardTitle}
          // disabled
          inputProps={{
            readOnly: true
          }}
        /> */}

        {/* <FormControl fullWidth sx={{ marginTop: '20px' }}>
          <InputLabel>Dashboard layout</InputLabel>

          <Select
            label="Dashboard layout"
            value={preselectedLayout}
            onChange={handlePreselectedLayout}
            MenuProps={{
              disableScrollLock: true
            }}
            sx={{}}
          >
            {' '}
            <MenuItem value={'Facility Management'}>
              Facility Management
            </MenuItem>
            <MenuItem value={'Energy management'}>Energy management</MenuItem>
            <MenuItem value={'Remote operations center'}>
              Remote operations center
            </MenuItem>
            <MenuItem value={'Janitorial procurement'}>
              Janitorial procurement
            </MenuItem>
            <MenuItem value={'Custom'}>Custom</MenuItem>
          </Select>
        </FormControl> */}

        <Typography
          sx={{
            fontStyle: 'normal',
            marginTop: '32px',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px',
            color: '#1A1A1A',
            letterSpacing: 0
          }}
        >
          Add widgets
        </Typography>

        <FormControl fullWidth sx={{ marginTop: '20px', marginBottom: '12px' }}>
          <InputLabel>Category</InputLabel>
          <Select
            label="Category"
            value={widgetCategory}
            onChange={handleWidgetCategory}
            MenuProps={{
              disableScrollLock: true
            }}
            sx={{}}
          >
            <MenuItem value={'All'}>All</MenuItem>
            {Object.keys(category).map((v) => (
              <MenuItem key={v} value={v}>
                {v}
              </MenuItem>
            ))}
            {/* <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'Assets'}>Assets</MenuItem> */}
            {/* <MenuItem value={'Work orders'}>Work orders</MenuItem> */}
            {/* <MenuItem value={'Service requests'}>Service requests</MenuItem> */}
            {/* <MenuItem value={'Maintenance'}>Maintenance</MenuItem> */}
            {/* <MenuItem value={'Energy & Sustainability'}>
              Energy & Sustainability
            </MenuItem> */}
            {/* <MenuItem value={'Legionella Monitoring'}>
              Legionella Monitoring
            </MenuItem>
            <MenuItem value={'Dynamic services'}>Dynamic services</MenuItem>
            <MenuItem value={'Weather'}>Weather</MenuItem> */}
          </Select>
        </FormControl>

        <div className="widget-list">
          <ToolBox
            items={(
              gridState.toolbox[gridState.currentBreakpoint] || []
            ).filter((item) => {
              return widgetCategory === 'All'
                ? true
                : widgets.find((v) => v.id === item.i)?.category ===
                    widgetCategory;
            })}
            onTakeItem={onTakeItem}
          />
        </div>
      </div>
    </Drawer>
  );
};
export const ToolBox = ({ items, onTakeItem }) => {
  return (
    <div className="toolbox">
      <div className="toolbox__items">
        {items.map((item) => (
          <ToolBoxItem key={item.i} item={item} onTakeItem={onTakeItem} />
        ))}
      </div>
    </div>
  );
};
export const ToolBoxItem = ({ item, onTakeItem }) => {
  let widget = widgets.find((e) => {
    return e.id === item.i;
  });
  return (
    widget && (
      <div
        // style={{
        //   border: '1px solid black',
        //   boxSizing: 'border-box'
        // }}
        className="toolbox__items__item"
        onClick={onTakeItem.bind(undefined, item)}
      >
        {widget?.content}
      </div>
    )
  );
};

import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { DashboardContext } from '../../../context/DashboardContext.jsx';
import { api } from '../../../utils/Api';
import { useNavigationCtx } from '../../NavigationProvider.jsx';
import BarChart from '../../charts/BarChart.jsx';
import { WidgetCard } from '../WidgetCard.jsx';
import { getWidgetFilterInterval } from '../../../utils/functions.js';
import dayjs from 'dayjs';
import '../widgets.scss';
import './AssetIssues.scss';

export default function AssetIssues() {
  const { refreshDuration, refreshEvent } = React.useContext(DashboardContext);
  const [dateRange, setDateRange] = useState('This month');
  const navigationCtx = useNavigationCtx();

  const widgetType = 'issues',
    interval =
      dateRange === 'This month'
        ? 'thisMonth'
        : dateRange === 'This week'
        ? 'thisWeek'
        : dateRange === 'Last week'
        ? 'lastWeek'
        : dateRange === 'Last month'
        ? 'lastMonth'
        : 'last30Days';
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const buildingName = navigationCtx?.state.selectedOption?.navName;

  const useIssuesWidget = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn:
      /**
       * @typedef {{
       *    widgetType: string;
       *    interval: string;
       *    buildingId: string;
       *    projectId: string;
       *    startDate: string;
       *    endDate: string;
       *    values: {
       *      field: string;
       *      value: string;
       *    }[];
       *    deviation: number;
       *    comparison: string;
       *    arrow: string;
       *    totalP1Issues: string;
       *  }} APIResponseData
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          // return await resolveAfterMs('eeerooor', 3000, false);
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           * */
          const response = await api.get(
            `issues/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType,
                interval
              }
            }
          );
          if (response.data.status === 'error')
            if (response.data.errorMessage)
              return {
                status: 'error',
                errorMessage:
                  response.data.errorMessage === 'No data for date range' ||
                  response.data.errorMessage === 'No data for record'
                    ? response.data.errorMessage
                    : 'unknown error'
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'"
              );
          if (!response.data.data)
            throw new Error("unable to find 'data' property in API response");
          return { status: 'success', data: response.data.data };
        } catch (e) {
          console.error(e || 'some error occured');
          return {
            status: 'error',
            errorMessage: 'unknown error'
          };
        }
      }
  });

  let data =
    (useIssuesWidget.data?.status === 'success' &&
      useIssuesWidget.data.data.values?.map((v) => ({
        name: v.field,
        value: v.value,
        color: '#80BBAD'
      }))) ||
    [];

  data.sort((a, b) => (a.value < b.value ? 1 : -1));
  const title = 'Issues';
  const getLink = () => {
    if (useIssuesWidget.data && useIssuesWidget.data?.errorMessage) {
      return '';
    }
    return `/ui/issue?recordType=site&view=issue&records=${buildingId}&recordId=${buildingName}&project=${projectId}&dateRange=${dayjs(
      useIssuesWidget.data?.data.startDate
    ).format('YYYY-MM-DD')}%2C${dayjs(
      useIssuesWidget.data?.data.endDate
    ).format('YYYY-MM-DD')}intervalType=${getWidgetFilterInterval(interval)}`;
  };
  return (
    <WidgetCard
      scrollContainer={<BarChart data={data} />}
      useQueryResult={useIssuesWidget}
      title={title}
      link={getLink()}
      dateRange={dateRange}
      setDateRange={setDateRange}
      headerValueComponent={
        useIssuesWidget.data?.status === 'success' ? (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#1A1A1A',
              letterSpacing: 0,
              textTransform: 'lowercase'
            }}
          >
            {useIssuesWidget.data?.data.totalP1Issues?.toLocaleString() ||
              '0 issue events'}
          </Typography>
        ) : null
      }
      valueChangeComponent={
        useIssuesWidget.data?.status === 'success' ? (
          <>
            <span
              className="material-symbols-outlined"
              style={{ fontSize: 14 }}
            >
              {useIssuesWidget.data?.data.arrow
                ? useIssuesWidget.data.data.arrow === 'up'
                  ? 'arrow_upward'
                  : 'arrow_downward'
                : null}
            </span>
            {useIssuesWidget.data?.data.comparison}
          </>
        ) : null
      }
    />
  );
}

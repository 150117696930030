import { NotificationImportant } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { DashboardContext } from '../../../context/DashboardContext.jsx';
import { api } from '../../../utils/Api.js';
import { useNavigationCtx } from '../../NavigationProvider.jsx';
import { WidgetBarChart } from '../../charts/WidgetBarChart.jsx';
import { WidgetCard, dateRangeId } from '../WidgetCard.jsx';
import '../widgets.scss';

export default function Occupancy() {
  const title = 'Occupancy';
  const widgetType = 'occupancy';
  const { refreshDuration, refreshEvent } = React.useContext(DashboardContext);
  const [dateRange, setDateRange] = useState('This month');
  const navigationCtx = useNavigationCtx();
  const interval = dateRangeId(dateRange);
  const projectId = navigationCtx?.state.selectedOption?.project_nexus_id;
  const buildingId = navigationCtx?.state.selectedOption?.nexusId;
  const query = useQuery({
    queryKey: [widgetType, interval, buildingId, projectId, refreshEvent],
    enabled: !!widgetType && !!interval && !!buildingId && !!projectId,
    refetchInterval: 1000 * parseInt(refreshDuration) * 60,
    queryFn:
      /**
       * @typedef {{
       *   widgetType: string;
       *   interval: string;
       *   buildingId: string;
       *   projectId: string;
       *   values: (
       *     | {
       *         day: string;
       *         value: number;
       *       }
       *     | {
       *         day: string;
       *         value: null;
       *       }
       *   )[];
       *   threshold: number;
       *   dailyPeakAvg: number;
       *   deviation: string;
       * }} APIResponseData
       * @typedef {'No data for date range' | 'No data for record'} APIResponseErrorMessage
       * @returns {import("./../types.js").QueryData<APIResponseData, APIResponseErrorMessage>}
       */
      async () => {
        try {
          // return await resolveAfterMs('eeerooor', 3000, false);
          /**
           * @type {import('axios').AxiosResponse<{
           *      status?: "success" | "error";
           *      data?: APIResponseData;
           *      errorMessage?: APIResponseErrorMessage;
           * }, any>}
           * */
          const response = await api.get(
            `smartcleaning/projects/${projectId}/buildings/${buildingId}/widgets`,
            {
              params: {
                widgetType,
                interval
              }
            }
          );
          if (response.data.status === 'error' || response.data.errorMessage)
            if (response.data.errorMessage)
              return {
                status: 'error',
                errorMessage:
                  response.data.errorMessage === 'No data for date range' ||
                  response.data.errorMessage === 'No data for record'
                    ? response.data.errorMessage
                    : 'unknown error'
              };
            else
              throw new Error(
                "API response status is 'error' without any 'errorMessage'"
              );
          if (!response.data.data)
            throw new Error("unable to find 'data' property in API response");
          return { status: 'success', data: response.data.data };
        } catch (e) {
          console.error(e || 'some error occured');
          return {
            status: 'error',
            errorMessage: 'unknown error'
          };
        }
      }
  });
  const data = useMemo(
    () =>
      query.data && query.data.status === 'success'
        ? query.data.data.values.map((v) => ({
            name: v.day,
            'Daily avg.': v.value
          }))
        : [],
    [query.data]
  );
  const getLink = () => {
    if (query.data && query.data?.errorMessage) {
      return '';
    }
    return `/ui/smart-cleaning?recordId=${buildingId}&tab=Overview`;
  };
  return (
    <WidgetCard
      scrollContainer={
        query.data?.status === 'success' ? (
          <WidgetBarChart
            data={data}
            otherData={{
              Threshold: query.data?.data.threshold,
              'Daily peak avg.': query.data?.data.dailyPeakAvg
            }}
          />
        ) : null
      }
      useQueryResult={query}
      title={title}
      link={getLink()}
      dateRange={dateRange}
      setDateRange={setDateRange}
      headerValueComponent={
        query.data?.status === 'success' ? (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '24px',
              lineHeight: '32px',
              color: '#1A1A1A',
              letterSpacing: 0,
              textTransform: 'lowercase'
            }}
          >
            <NotificationImportant />
            {`${query.data.data.dailyPeakAvg}% avg. peak`}
          </Typography>
        ) : null
      }
      valueChangeComponent={
        <>
          <span className="material-symbols-outlined" style={{ fontSize: 14 }}>
            {query.data?.status === 'success'
              ? query.data.data.deviation
                ? query.data.data.deviation === 'up'
                  ? 'arrow_upward'
                  : 'arrow_downward'
                : null
              : null}
          </span>
          1% {dateRange.toLowerCase()}
        </>
      }
    />
  );
}

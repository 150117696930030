import { CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { ErrorCardContent } from './ErrorCardContent.jsx';
import TimePicker from './TimePicker/TimePicker.jsx';
// import { resolveAfterMs } from '@smartfm/react-components';

/**
 * @template {string} ErrorMessage
 * @template {unknown} Data
 * @param {{
 *  scrollContainer: import('react').ReactNode,
 *  useQueryResult:  import('@tanstack/react-query').UseQueryResult<{
 *          status: "success";
 *          data: Data;
 *      } | {
 *          status: "error";
 *          errorMessage: ErrorMessage | "unknown error";
 *      }, Error>
 *  title?: string;
 *  link?: string;
 *  dateRange?: string;
 *  setDateRange?: import('react').Dispatch<import('react').SetStateAction<string>>;
 *  noDataForTimeRange?: boolean;
 *  headerValueComponent: import('react').ReactNode;
 *  valueChangeComponent: import('react').ReactNode;
 * icon?: import('react').ReactNode;
 * preview?: import('react').ReactNode;
 * timeDurationOptions?: {label:string, isDisabled?:boolean}[];
 * }} props
 */
export const WidgetCard = ({
  scrollContainer,
  useQueryResult,
  title,
  link,
  dateRange,
  setDateRange,
  noDataForTimeRange,
  headerValueComponent,
  valueChangeComponent,
  icon,
  preview,
  timeDurationOptions
}) => {
  return (
    <div className="widget">
      {useQueryResult.isFetching ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <CircularProgress sx={{ m: 'auto' }} />
        </div>
      ) : useQueryResult.isError ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <ErrorCardContent onReload={() => useQueryResult.refetch()} />
        </div>
      ) : !useQueryResult.data ? (
        <div className="top" style={{ height: '100%' }}>
          <div className="top">
            <CardTitle title={title} icon={icon} />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}
          >
            <Typography
              // @ts-ignore
              variant="Calibre/Body 2"
              color={'rgba(26, 26, 26, 0.65)'}
            >
              {'No data is available'}
            </Typography>
          </div>
        </div>
      ) : useQueryResult.data.status === 'error' ? (
        useQueryResult.data.errorMessage === 'No data for record' ? (
          <div className="top" style={{ height: '100%' }}>
            <div className="top">
              <CardTitle title={title} icon={icon} />
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%'
              }}
            >
              <Typography
                // @ts-ignore
                variant="Calibre/Body 2"
                color={'rgba(26, 26, 26, 0.65)'}
              >
                {'No data is available'}
              </Typography>
            </div>
          </div>
        ) : useQueryResult.data.errorMessage === 'No data for date range' ? (
          <WidgetContentForNoDataForTimeRange
            timeDurationOptions={
              timeDurationOptions ?? [
                { label: 'This week' },
                { label: 'Last week' },
                { label: 'This month' },
                { label: 'Last month' },
                { label: 'Last 30 days' }
              ]
            }
            title={title}
            link={''}
            dateRange={dateRange}
            setDateRange={setDateRange}
            icon={icon}
          />
        ) : (
          <div className="top" style={{ height: '100%' }}>
            <div className="top">
              <CardTitle title={title} link={link} icon={icon} />
            </div>
            <ErrorCardContent onReload={() => useQueryResult.refetch()} />
          </div>
        )
      ) : noDataForTimeRange ? (
        <WidgetContentForNoDataForTimeRange
          timeDurationOptions={
            timeDurationOptions ?? [
              { label: 'This week' },
              { label: 'Last week' },
              { label: 'This month' },
              { label: 'Last month' },
              { label: 'Last 30 days' }
            ]
          }
          title={title}
          link={link}
          dateRange={dateRange}
          setDateRange={setDateRange}
          icon={icon}
        />
      ) : (
        <div className="top" style={{ height: '100%' }}>
          {preview}
          <div className="top">
            <CardTitle title={title} link={link} icon={icon} />
            <div className="value">
              <div className="val">{headerValueComponent}</div>
            </div>
            {dateRange && setDateRange ? (
              <div className="time-range">
                <TimePicker
                  defaultVal={dateRange}
                  onChangeDateRange={setDateRange}
                  timeDurationOptions={
                    timeDurationOptions ?? [
                      { label: 'This week' },
                      { label: 'Last week' },
                      { label: 'This month' },
                      { label: 'Last month' },
                      { label: 'Last 30 days' }
                    ]
                  }
                />
                <div className="value-change">{valueChangeComponent}</div>
              </div>
            ) : null}
          </div>
          {scrollContainer}
        </div>
      )}
    </div>
  );
};
const CardTitle = ({ title, link = '', icon }) =>
  title || icon ? (
    <div className="label">
      <span>
        {link && link !== '' ? (
          <Link to={link}>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '20px',
                // color: '#1A1A1A',
                letterSpacing: 0
              }}
            >
              {title}
            </Typography>
          </Link>
        ) : (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20px',
              letterSpacing: 0
            }}
          >
            {title}
          </Typography>
        )}
      </span>
      {icon}
    </div>
  ) : null;
const WidgetContentForNoDataForTimeRange = ({
  title,
  link,
  dateRange,
  setDateRange,
  icon,
  timeDurationOptions
}) => (
  <div className="top" style={{ height: '100%' }}>
    <div className="top">
      <CardTitle title={title} link={link} icon={icon} />
    </div>
    <Stack
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100%',
        gap: '4px'
      }}
    >
      No data is available for this time range
      <TimePicker
        defaultVal={dateRange}
        onChangeDateRange={setDateRange}
        timeDurationOptions={timeDurationOptions}
      />
    </Stack>
  </div>
);

export function dateRangeId(dateRangeLabel) {
  return dateRangeLabel === 'This month'
    ? 'thisMonth'
    : dateRangeLabel === 'This week'
    ? 'thisWeek'
    : dateRangeLabel === 'Last week'
    ? 'lastWeek'
    : dateRangeLabel === 'Last month'
    ? 'lastMonth'
    : 'last30Days';
}

import React from 'react';
import {
  Bar,
  ComposedChart,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { LegendIcon } from './LegendIcon';
import { LegendItem } from './LegendItem';
import './../cards/widgets.scss';
import { Stack } from '@mui/material';

export function WidgetBarChart({ data, otherData }) {
  return (
    <Stack
      className="widget-chart"
      sx={{
        '.recharts-wrapper': {
          height: '98% !important'
        }
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          // margin={{ top: 5, right: 5, left: 5, bottom: 20 }}
          role="chart"
          data={data}
          onMouseMove={(e) => {
            //
          }}
          barCategoryGap="30%"
        >
          <XAxis
            role="x-axis"
            dataKey="name"
            axisLine={false}
            width={0}
            height={10}
            tickLine={false}
          />
          <YAxis
            role="left-y-axis"
            tickCount={3}
            tickLine={false}
            axisLine={true}
            width={25}
            stroke={'#E3E3E3'}
            domain={[0, 100]}
          />
          <Bar dataKey="Daily avg." fill="rgba(128, 187, 173, 1)" />
          <ReferenceLine
            y={otherData['Threshold']}
            color=""
            // label={"Threshold"}
            stroke="rgba(210, 120, 90, 1)"
          />
          <ReferenceLine
            y={otherData['Daily peak avg.']}
            // label="Daily peak avg."
            strokeDasharray={'5 5'}
            stroke="rgba(67, 83, 84, 1)"
          />

          <Tooltip
            formatter={(value) => {
              const v = value.valueOf();
              if (typeof v === 'number') return v.toFixed(2);
              else {
                return value;
              }
            }}
            cursor={false}
          />
          <Legend
            content={(props) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    gap: '4px 20px',
                    marginTop: '12px',
                    padding: '8px'
                  }}
                >
                  {props.payload?.map((pi) => {
                    return (
                      <LegendItem
                        icon={
                          pi.type === 'rect' ? (
                            <LegendIcon
                              variant={'rect'}
                              color={'rgba(128, 187, 173, 1)'}
                            />
                          ) : null
                        }
                        label={pi.value}
                      />
                    );
                  })}
                  <LegendItem
                    icon={
                      <LegendIcon
                        variant={'line'}
                        color={'rgba(210, 120, 90, 1)'}
                      />
                    }
                    label={'Threshold'}
                  />
                  <LegendItem
                    icon={
                      <LegendIcon
                        variant={'dotted-line'}
                        color={'rgba(67, 83, 84, 1)'}
                      />
                    }
                    label={'Daily peak avg.'}
                  />
                </div>
              );
            }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Stack>
  );
}
